body {
    padding: 0;
    background-color: #f8f8f8 !important;
}

@font-face {
    font-family: 'GT-America-Medium';
    src: url("./assets/fonts/GT-America-Standard-Medium.woff2") format('woff2');
}

@font-face {
    font-family: 'GT-America-Regular';
        src: url("./assets/fonts/GT-America-Standard-Regular.woff2") format('woff2');
}

@font-face {
    font-family: 'Helvetica';
    src: url("./assets/fonts/Helvetica.woff2") format('woff2');
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: black;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

// slick slider

.slick-dots li button:before {
    color: #D9D9D9 !important;
}
.slick-dots li.slick-active button:before{
    color: #fff !important;
}

.slick-dots {
  bottom: 10px !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-next {
  right: 10px !important;
  z-index: 1;
}

.slick-prev {
  left: 10px !important;
  z-index: 1;
}

.slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

:focus-visible {
  outline: none;
}

// To disable zoom on inputs on mobile
@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.75) !important;
}