.drawerPaper {
  margin: 0 auto;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background-color: #000000 !important;
  background-image: none !important;
}
.container {
  margin: 30px;
  max-width: 500px;
}

.puller {
  position: absolute;
  width: 48px;
  height: 2px;
  left: calc(50% - 24px);
  top: 10px;
  border-radius: 2px;
}

.confirmText {
  color: rgba(255, 255, 255, 0.5);
}

.confirmText span {
  color: #FFFFFF;
  font-weight: bold;
}